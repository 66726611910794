import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ExistingUserRegisterForFamilyTrust = () => {
  return (
    <Layout>
      <Seo
        title="How Can an Existing User Register for Family Trust?"
        description={`In family trust registration, users are given easy instructions on how to achieve verification status after submitting complete details through the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`existing-user-register-for-family-trust`} />
    </Layout>
  )
}

export default ExistingUserRegisterForFamilyTrust
